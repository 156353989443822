<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M499-287l335-335-52-52-335 335 52 52zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33z"/></svg>

</template>


<script>

  export default {"name":"icon-5be08565c49472e5626c779380e501f9"}

</script>