<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-800v-120h80v120h-80zm0 760v-120h80v120h-80zm360-400v-80h120v80H800zm-760 0v-80h120v80H40zm708-252l-56-56 70-72 58 58-72 70zM198-140l-58-58 72-70 56 56-70 72zm564 0l-70-72 56-56 72 70-58 58zM212-692l-72-70 58-58 70 72-56 56zm268 452q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70zm0-80q67 0 113.5-46.5T640-480q0-67-46.5-113.5T480-640q-67 0-113.5 46.5T320-480q0 67 46.5 113.5T480-320zm0-160z"/></svg>

</template>


<script>

  export default {"name":"icon-96dd1310c50623a999cceccc7db0e33d"}

</script>