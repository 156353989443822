var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-select",class:_vm.classes,style:({
    'border-bottom-color':
      _vm.isActive && !_vm.disabled ? _vm.$themeBrand.primary.v_600 : _vm.$themePalette.grey.v_700,
  })},[(_vm.name)?_c('input',{staticClass:"ui-select-hidden-input",attrs:{"type":"hidden","name":_vm.name},domProps:{"value":_vm.submittedValue}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ui-select-content"},[_c('div',{ref:"label",staticClass:"ui-select-label",class:_vm.$computedClass({ ':focus': _vm.$coreOutline }),attrs:{"tabindex":_vm.disabled ? null : '0'},on:{"focus":_vm.onFocus,"blur":_vm.selectBlur,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.onEnterSpace.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.onEnterSpace.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.onBlur.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.highlightPreviousOption.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.highlightNextOption.apply(null, arguments)},function($event){if($event.target !== $event.currentTarget)return null;return _vm.highlightQuickMatch.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.closeDropdown()}]}},[(_vm.label || _vm.$slots.default)?_c('div',{staticClass:"ui-select-label-text",class:_vm.labelClasses,style:({
          color: _vm.isActive ? _vm.$themeTokens.primary : _vm.$themePalette.grey.v_700,
        })},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ui-select-display",style:(_vm.activeBorderStyle)},[_c('div',{staticClass:"ui-select-display-value",class:{ 'is-placeholder': !_vm.hasDisplayText }},[_vm._t("display",function(){return [_vm._v("\n            "+_vm._s(_vm.hasDisplayText
                ? _vm.displayText
                : _vm.hasFloatingLabel && _vm.isLabelInline
                  ? null
                  : _vm.placeholder)+"\n          ")]})],2),_vm._v(" "),(!_vm.clearableState)?_c('UiIcon',{staticClass:"ui-select-dropdown-button",style:({ color: _vm.isActive ? _vm.$themeTokens.primary : _vm.$themePalette.grey.v_700 })},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M6.984 9.984h10.03L12 15z"}})])]):_c('KIconButton',{staticClass:"overlay-close-button",attrs:{"icon":"close","ariaLabel":_vm.clearText,"color":_vm.$themeTokens.text,"tooltip":_vm.clearText},on:{"click":function($event){$event.stopPropagation();return _vm.setValue()}}})],1),_vm._v(" "),(_vm.appendToEl)?_c('Popper',{ref:"dropdownPopper",attrs:{"closeOnScroll":"","transition":"ui-select-transition-fade","trigger":"click","appendToEl":_vm.appendToEl,"reference":_vm.$refs.label,"visibleArrow":false,"options":_vm.dropdownPopperOptions,"disabled":_vm.disabled},on:{"show":_vm.onOpen,"hide":_vm.onClose}},[_c('div',{ref:"dropdown",staticClass:"ui-select-dropdown",style:({
            color: _vm.$themeTokens.primary,
            backgroundColor: _vm.$themeTokens.surface,
            bottom: _vm.dropdownButtonBottom,
          })},[_c('ul',{ref:"optionsList",staticClass:"ui-select-options",style:({ backgroundColor: _vm.$themeTokens.surface })},[_vm._l((_vm.filteredOptions),function(option,index){return _c('KSelectOption',{key:index,ref:"options",refInFor:true,attrs:{"highlighted":_vm.isOptionHighlighted(option),"keys":_vm.keys,"multiple":_vm.multiple,"option":option,"selected":_vm.isOptionSelected(option),"type":"basic"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.selectOption(option)},"mouseover":function($event){$event.stopPropagation();return _vm.onMouseover(option)}}},[_vm._t("option",null,{"highlighted":_vm.isOptionHighlighted(option),"index":index,"option":option,"selected":_vm.isOptionSelected(option)})],2)}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNoResults),expression:"hasNoResults"}],staticClass:"ui-select-no-results"},[_vm._t("no-results",function(){return [_vm._v("\n                "+_vm._s(_vm.noResultsText)+"\n              ")]})],2)],2)])]):_vm._e()],1),_vm._v(" "),(_vm.hasFeedback)?_c('div',{staticClass:"ui-select-feedback"},[(_vm.showError)?_c('div',{staticClass:"ui-select-feedback-text"},[_vm._t("error",function(){return [_vm._v("\n          "+_vm._s(_vm.invalidText)+"\n        ")]})],2):(_vm.showHelp)?_c('div',{staticClass:"ui-select-feedback-text"},[_vm._t("help",function(){return [_vm._v("\n          "+_vm._s(_vm.help)+"\n        ")]})],2):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }