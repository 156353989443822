<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2a10 10 0 0110 10 10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2m0 2a8 8 0 00-8 8 8 8 0 008 8 8 8 0 008-8 8 8 0 00-8-8m0 6.5a1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5 1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 011.5-1.5m-4.5 0A1.5 1.5 0 019 12a1.5 1.5 0 01-1.5 1.5A1.5 1.5 0 016 12a1.5 1.5 0 011.5-1.5m9 0A1.5 1.5 0 0118 12a1.5 1.5 0 01-1.5 1.5A1.5 1.5 0 0115 12a1.5 1.5 0 011.5-1.5z"/></svg>

</template>


<script>

  export default {"name":"icon-1e80e586da51c48ff14201dbf5af5da5"}

</script>