<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M260-520l220-360 220 360H260zM700-80q-75 0-127.5-52.5T520-260q0-75 52.5-127.5T700-440q75 0 127.5 52.5T880-260q0 75-52.5 127.5T700-80zm-580-20v-320h320v320H120zm580-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29zm-500-20h160v-160H200v160zm202-420h156l-78-126-78 126zm78 0zM360-340zm340 80z"/></svg>

</template>


<script>

  export default {"name":"icon-31ec166b134f9d8fe361ae8b7fa6f25b"}

</script>