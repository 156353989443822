<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#e8eaed"><path d="M320-240L80-480l240-240 57 57-184 184 183 183-56 56zm320 0l-57-57 184-184-183-183 56-56 240 240-240 240z"/></svg>

</template>


<script>

  export default {"name":"icon-acc9ffc9ffc227bbc1a5234a3216c548"}

</script>